<template>
  <div class="page-wrap phone-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">智慧农业平台解决方案</div>
          <div class="desc">将物联网、人工智能技术等与农业实际生产紧密结合, 对种植进行智能化精准管护，实现水土气、作物长势、虫情、干旱、洪涝、作物产量等农情感知，农产品全产业链质量溯源与追溯，资源综合管理调度。打造一体化智慧农业经营模式，全面提高农业全产业链智能化、科学化管理水平。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 总体架构 -->
    <div class="section bg-white arc">
      <div class="title">总体架构</div>
      <div class="content">
        <div class="bg-arc"></div>
      </div>
    </div>

    <!-- 高标准农田管理平台 -->
    <div class="section platform">
      <div class="title">高标准农田管理平台</div>
      <div class="sub-title">高标准农田综合管理平台是在传统农田管理的基础上，运用地理信息系统(GIS)、全球定位系统(GPS)、遥感技术(RS)等技术手段，实现对农田的智能化、精细化管理。通过该平台，农田管理者可以更加直观地了解农田情况，从气象信息、土壤养分、病虫害发生到作物长势等各方面进行精准把控，提高农业生产效率。系统组成如下：</div>
      <div class="content">
        <div class="bg-plat-1"></div>
      </div>
    </div>

    <!-- 农产品溯源平台 -->
    <div class="section bg-white platform">
      <div class="title">农产品溯源平台</div>
      <div class="content">
        <div class="bg-plat-2"></div>
      </div>
    </div>

    <!-- 农业产业大脑 -->
    <div class="section brain">
      <div class="title">农业产业大脑</div>
      <div class="sub-title">利用GIS、卫星遥感、无人机+激光雷达/多光谱相机等现代化信息技术，通过对卫星遥感时空数据和农业、农村、政务、乡村振兴、涉农人口、土地资源、冷链冷库等业务数据进行融合及挖掘分析，构建农业时空数据—张图，形成农村基础资源、业务数据资源、农村产业分布等专题应用，提高经营管理的实时化、可视化、精细化。</div>
      <div class="content">
        <div class="detail-brain">
          <img class="image-item" src="@/assets/agr-platform/image-1.jpg" />
          <img class="image-item" src="@/assets/agr-platform/image-2.jpg" />
          <img class="image-item" src="@/assets/agr-platform/image-3.jpg" />
        </div>
      </div>
    </div>

    <!-- 案例：淮南农业产业服务平台 -->
<!--    <div class="section bg-white case">
      <div class="title">案例：淮南农业产业服务平台</div>
      <div class="sub-title">背景：2021年，淮南市委、市政府出台实施《关于进一步推进农业生产大托管改革试点工作的意见》，多次召开专题会议高位推动“大托管”改革。 “大托管”改革的核心做法是“两委托两跟进一托底”。</div>
      <div class="content">
        <div class="bg-case-1"></div>
      </div>
    </div>-->

    <!-- 案例：淮南农业产业服务平台-建设成效 -->
<!--    <div class="section case">
      <div class="title">案例：淮南农业产业服务平台-建设成效</div>
      <div class="content">
        <div class="detail-case">
          <div class="d-item">
            <div class="title">建设目标</div>
            <div class="content">推进淮南农业“大托管”业务，全力打造集委托合同档案管理、农业技术服务、农资集采销售、农业技术培训等一站式农业社会化服务平台。根据淮南市农业大托管产业服务平台的战略定位及业务开展现状，整合现有电子证照平台，构建农管家服务平台业务大图，最终通过平台构建，实现淮南农业产业规模化、标准化、品牌化和科学化的发展，打造安徽“两强一增”行动计划的淮南样板，支撑淮南 300 万亩农业大托管业务的顺利开展，解决委托合同签约及档案管理、农业产业服务运营等实际问题。</div>
          </div>
          <div class="block-title">建设成效</div>
          <div class="effect-wrap">
            <div class="left">
              <div class="e-list">
                <div class="item">
                  <span class="c-blue">托管成效：</span>有363个村开展托管，服务主体712个，21个示范县示范面积共485万亩。粮食播种面积的10.6%。
                </div>
                <div class="item">
                  <span class="c-blue">农民增收：</span>2.38万名托管农户户均增收2470元，135个村平均增收7万元，累计减少“冬闲田”面积约10万亩。
                </div>
                <div class="item">
                  <span class="c-blue">金融活水加速进田：</span>截至目前，全省示范县共计发放各类支持“大托管”贷款1.25亿元。
                </div>
              </div>
            </div>
            <div class="right">
              <img class="image-case" src="@/assets/agr-platform/image-case.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      valueList: [
        {
          name: '标准化、规范化管理流程， 减少人员15%以上',
          icon: 'i1.png'
        },
        {
          name: '物联数据联动与分析，降低运 营成本10%以上',
          icon: 'i2.png'
        },
        {
          name: '节能策略，降低能耗10% 以上',
          icon: 'i3.png'
        },
        {
          name: '智慧报警、异常感知，多端联动，快速警报',
          icon: 'i4.png'
        },
        {
          name: '业务场景闭环，协同各子系统 ，深挖场景增值服务',
          icon: 'i5.png'
        },
        {
          name: '形象展示窗口，体现先进管理 理念，提升企业形象',
          icon: 'i6.png'
        },
        {
          name: '管理人员辅助决策， 快速 掌控整体态势',
          icon: 'i7.png'
        },
        {
          name: '数据互联互通，打破信息孤岛， 提升用户体验',
          icon: 'i8.png'
        }
      ],
      planList: [
        {
          name: '数字园区',
          content: `“统一标准、数据集中、应用集成、硬件集群”，对信息资源 进行整合与优化，构建一体化数字园区平台`,
        },
        {
          name: '绿色园区',
          content: `通过软硬件一体化建设，实现能源管控与高效使用，打造绿色 园区文化，实现园区的节能与环保`,
        },
        {
          name: '平安园区',
          content: `人防、技防、物防相结合，构建事前智能感知预警、事中实时 发现与追踪、事后综合分析的管理模式，实现园区安全和谐`,
        },
        {
          name: '感知园区',
          content: `以物联网为基础，基于可视化的应用服务，对园区的各类物联 终端进行集成与监控，实现园区的智能全感知`,
        },
        {
          name: '互联园区',
          content: `基于多源网络融合与移动互联网，将企业服务、员工服务、生 活服务进行无缝链接，实现园区的智慧办公与智慧生活的目标`,
        }
      ]
    }
  },
  methods: {
    getImage(item) {
      return require(`@/assets/community/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
